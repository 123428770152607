.primary-btn {
  background-color: #ece4f1;
  border: none;
  display: flex;
  align-items: center;
  padding: 8px 12px;
  border-radius: 10px;
  text-transform: capitalize;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 2s ease, border 1s ease, transform 2s ease,
    color 2s ease;
  box-sizing: border-box;
}
.icon-btn {
  height: 25px;
  width: 25px;
  color: #7100bc;
  transition: color 2s ease;
}
.primary-btn:hover {
  border: 1.2px solid #4b02a4;
  transform: scale(1.05);
  background-color: #4b02a4;
  color: #fff;
}

.primary-btn:hover .icon-btn {
  color: #fff;
}
/*  */
.secondaryBtn {
  border: 2px solid transparent;
  background-color: #4b02a4;
  padding: 8px 12px;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  transition: background-color 1s ease-in-out, color 1s ease-in-out,
    border 1s ease-in-out, transform 1s ease-in-out;
}

.secondaryBtn:hover {
  background-color: #f3f2f2;
  color: #4b02a4;
  border: 2px solid #4b02a4;
  transform: scale(1.02);
}

/*  */

.thirdBtn {
  border: none;
  background-color: #4b02a4;
  width: 350px;
  padding: 16px 14px;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  transition: background-color 1s ease-in-out, color 1s ease-in-out,
    border 1s ease-in-out, transform 1s ease-in-out;
}

.thirdBtn:hover {
  background-color: #f3f2f2;
  color: #4b02a4;
  border: 2px solid #4b02a4;
  transform: scale(1.02);
}

.fourthbutton {
  border: 2px solid transparent;
  background-color: #4b02a4;
  font-weight: 600;
  padding: 15px 0px;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  width: 100%;
  transition: background-color 1s ease-in-out, color 1s ease-in-out,
    border 1s ease-in-out, transform 1s ease-in-out;
}
