.contact-container {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  width: 97%;
  margin: auto;
  gap: 30px;
}
.contact-left img {
  height: 400px;
  width: 100%;
  object-fit: cover;
}

.contact-container__right {
  background-color: #fff;
  padding: 10px;
  border-radius: 15px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
}

.contact-form {
  box-sizing: border-box;
}

.contact-form label span {
  font-weight: 500;
}

.contact-form input {
  box-sizing: border-box;
  border: 2px solid #d5b9e1;
  outline: none;
  border-radius: 5px;
}

.contact-form textarea {
  box-sizing: border-box;
  resize: none;
  border: 2px solid #d5b9e1;
  outline: none;
  border-radius: 5px;
}
.contact-container h1 {
  font-size: 36px;
  margin-bottom: 30px;
}

.contact-info {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.contact-info div {
  width: 30%;
}

.contact-container h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.contact-container input,
.contact-container textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
}

.contact-container button {
  padding: 10px 20px;
  background-color: #8f62c5;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
}

.contact-container button:hover {
  background-color: #764ea5;
}

@media (min-width: 768px) {
  .contact-container {
    grid-template-columns: repeat(2, 1fr);
    margin: 60px auto;
  }
  .contact-left img {
    height: 700px;
    width: 100%;
    object-fit: cover;
  }

  .contact-container__right {
    padding: 40px;
  }
}

@media (min-width: 1000px) {
  .contact-container {
    width: 90%;
  }
}

@media (min-width: 1200px) {
  .contact-container {
    width: 80%;
  }
}
