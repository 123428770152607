/* header Slider */
.headerslider {
  height: 35vh;
  position: relative;
}

.headerslider-img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  /* object-position: center 0px; */
}

.headerslider {
  height: 16vh;
  position: relative;
}

@media (min-width: 530px) {
  .headerslider {
    height: 30vh;
    position: relative;
  }
  .headerslider-img {
    object-fit: cover;
  }
}

@media (min-width: 1280px) {
  .headerslider-img {
    object-fit: cover;
  }

  .headerslider {
    height: 68vh;
    position: relative;
  }
}

/* carousel */
.carousel {
  padding: 30px 0px;
  position: relative;
}

.carousel-items {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 300px;
  grid-gap: 1.5rem;
}

.carousel-item {
  height: 100%;
  width: 100%;
}

.carousel-item_img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

@media (min-width: 640px) {
  .carousel-items {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 400px;
  }
}

@media (min-width: 768px) {
  .carousel-items {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 1200px) {
  .carousel-items {
    grid-template-columns: repeat(4, 1fr);
  }
}
/* popular carousel */
.popular-header {
  padding: 0px;
  margin: 0px 20px;
  font-size: 1.5rem;
  color: #36144e;
  font-weight: 600;
}

.popular {
  padding: 30px 0px 40px 0px;
  position: relative;
}

.popular-items {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 440px;
  grid-gap: 1rem;
  margin: auto;
  position: relative;
  width: 100%;
}

.popular-item {
  height: 100%;
  width: 100%;
  border: 2px solid #acacac;
  border-radius: 10px;
  padding: 10px;
  box-sizing: border-box;
}

.popular-item_img {
  height: 65%;
  width: 100%;
  object-fit: cover;
}

.popular-item__description {
  height: 35%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: start;
}
.popular-item__description span {
  font-size: 1rem;
  font-weight: 600;
}
.popular-item__description h4 {
  padding: 5px 0px;
  margin: 0px;
  font-size: 0.9rem;
  font-weight: 400;
}

@media (min-width: 640px) {
  .popular-items {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 768px) {
  .popular-items {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 1200px) {
  .popular-items {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media (min-width: 1500px) {
  .popular-items {
    grid-template-columns: repeat(6, 1fr);
  }
}
