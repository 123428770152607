.new-product {
  width: 100%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  padding: 40px;
  border-radius: 10px;
}

.new-product__form {
  width: 100%;
}

.new-product__form .text-input {
  width: 100%;
  margin-bottom: 15px;
}

.new-product__form-specifi {
  display: flex;
  align-items: center;
}

.new-product__form-specifi .text-input:first-child {
  margin-right: 10px;
}

.new-product__form__items {
  list-style: none;
  padding: 0px;
}

.new-product__form__item {
  display: flex;
  justify-content: space-between;
  background-color: #ece4f1;
  margin-bottom: 10px;
  padding: 5px 20px 5px 5px;
  position: relative;
}

.new-product__form__item span:first-child {
  margin-right: 20px;
}

.new-product__form__item-remove {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}

.new-product__form-file__input {
  height: 70px;
  width: 100px;
  border: solid 3px #ece4f1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  border-radius: 10px;
  cursor: pointer;
}
.new-product__form-file__input svg {
  height: 50px;
  width: 50px;

  color: #4b02a4;
}

.new-product__form-imgs {
  display: flex;
  padding: 10px 0px;
}

.new-product__form-img {
  height: 100px;
  width: 100px;
  margin-right: 10px;
  position: relative;
}

.new-product__form-img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.new-product__form-img svg {
  position: absolute;
  top: 0;
  right: 0;
  height: 35px;
  width: 35px;
  cursor: pointer;
  color: red;
}
