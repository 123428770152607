.favorite {
  width: 97%;
  margin: 20px auto;
}
.favorite__cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-auto-rows: 350px;
  gap: 20px;
  box-sizing: border-box;
}

.empty-favori__ontainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 300px;
}

.empty-favori__empty {
  text-align: center;
}
.empty-favori__empty h2 {
  font-size: 1.2rem;
  margin-bottom: 10px;
}
.empty-favori__empty p {
  font-size: 16px;
  color: #666;
  margin-bottom: 20px;
}
.btn-back-to-shop {
  display: inline-block;
  padding: 10px 20px;
  background-color: #4b02a4;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}
.btn-back-to-shop:hover {
  background-color: #3e0682;
}

@media (min-width: 768px) {
  .favorite {
    width: 70%;
  }
  .empty h2 {
    font-size: 2rem;
    margin-bottom: 10px;
  }
}

@media (min-width: 1200px) {
  .favorite__cards > * {
    max-width: 450px;
  }
}
@media (min-width: 1500px) {
  .favorite {
    width: 50%;
  }
}
