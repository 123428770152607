.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px;
}

.pagination-button {
  cursor: pointer;
  border: 1px solid #4b02a4;
  margin: 2px;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.6s ease, color 0.6s ease;
}

.pagination-button:hover {
  background-color: #4b02a4;
  color: #fff;
}

.pagination-button.active {
  background-color: #4b02a4;
  color: #fff;
}
