.not-found-container {
  text-align: center;
  padding: 50px;
}

.not-found-container h1 {
  font-size: 100px;
  margin-bottom: 10px;
}

.not-found-container h2 {
  font-size: 36px;
  margin-bottom: 20px;
}

.not-found-container p {
  font-size: 18px;
  margin-bottom: 30px;
}

.not-found-container a {
  text-decoration: none;
  color: #4b02a4;
  font-size: 20px;
  transition: color 0.3s ease;
}

.not-found-container a:hover {
  color: #7f52b6;
}
