.terms-of-sale-container {
  max-width: 80%;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  /* border-radius: 8px; */
  /* box-shadow: 0 0 20px rgba(0, 0, 0, 0.029); */
  margin-top: 12px;
}

.terms-of-sale-container h2 {
  color: #333;
  font-size: 28px;
  margin-bottom: 20px;
}

.terms-content {
  display: grid;
  grid-template-columns: 1fr;
  gap: 40px;
}

.terms-content h3 {
  color: #555;
  font-size: 22px;
  margin-bottom: 10px;
}

.terms-content p {
  color: #777;
  line-height: 1.6;
}

.terms-footer {
  margin-top: 20px;
  font-size: 14px;
  color: #999;
}

@media (min-width: 768px) {
  .terms-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px;
  }
}
