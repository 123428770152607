.search {
  width: 97%;
  margin: 20px auto;
}
.search-title {
  font-size: 1.2rem;
}
.search__cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-auto-rows: 350px;
  gap: 20px;
  box-sizing: border-box;
}

.search-empty__container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 300px;
}
.search-empty {
  text-align: center;
}
.search-empty h2 {
  font-size: 1.2rem;
  margin-bottom: 10px;
}
.search-empty p {
  font-size: 16px;
  color: #666;
  margin-bottom: 20px;
}
.btn-back-to-shop {
  display: inline-block;
  padding: 10px 20px;
  background-color: #4b02a4;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}
.btn-back-to-shop:hover {
  background-color: #3e0682;
}
@media (min-width: 768px) {
  .search-empty h2 {
    font-size: 2rem;
    margin-bottom: 10px;
  }
}
@media (min-width: 768px) {
  .search {
    width: 70%;
  }
}

@media (min-width: 1200px) {
  .search__cards > * {
    max-width: 450px;
  }
}
@media (min-width: 1500px) {
  .search {
    width: 50%;
  }
}
