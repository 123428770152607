/* @import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap'); */

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Protest+Riot&display=swap');
html {
  height: 100vh !important;
}
body {
  margin: 0px !important;
  padding: 0px !important;
  box-sizing: border-box !important;
  background-color: #f3f2f2;
  font-family: 'Roboto', sans-serif;
}
#root {
  display: grid;
  grid-template-rows: 150px 2fr 1fr;
  height: 100vh;
  grid-gap: 0px !important;
}

header {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  height: 100%;
  background-color: #ebebeb;
  padding: 20px 0px;
  box-sizing: border-box;
  position: relative;
  z-index: 100;
}
main {
  /* min-height: 55vh; */
  position: relative;
}
a {
  text-decoration: none;
  color: rgb(2, 5, 44);
}

h1,
h2,
h3,
h4,
h5,
p {
  padding: 0;
  margin: 0;
}

/* #root{
  display: grid;

} */

.error {
  display: inline-block;
  background-color: #f7d4d4f5;
  padding: 5px 20px;
  margin: 5px 0px;
  color: red;
}

/* toasify */

.Toastify__toast-container {
  width: 100% !important;
  margin: auto;
}

@media (min-width: 1000px) {
  .Toastify__toast-container {
    width: 20% !important;
  }
}
