.privacy-policy-container {
  width: 97%;
  margin: auto;
}

.privacy-policy-container__header h2 {
  font-size: 1.3rem;
}

.privacy-policy-container__header {
  padding: 20px 0px;
}

.policy-list {
  list-style: none;
  padding: 0px;
}

.policy-list li {
  margin-bottom: 25px;
}

.policy-list li h3 {
  margin-bottom: 10px;
  font-size: 1.1rem;
}

@media (min-width: 768px) {
  .privacy-policy-container {
    width: 80%;
    margin: auto;
  }

  .privacy-policy-container__header h2 {
    font-size: 1.6rem;
  }

  .policy-list li h3 {
    margin-bottom: 10px;
    font-size: 1.3rem;
  }
}
