.header {
  width: 97%;
  margin: auto;
  display: none;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #ece4f1;
  box-sizing: border-box;
}

.header-left__list {
  list-style: none;
  display: flex;
}

.header-left__list-el {
  display: flex;
  align-items: center;
  margin-right: 15px;
}

.header-left__list-el:nth-child(1) {
  border-right: 2px solid #c0bfc1;
  padding-right: 10px;
}

.header-left__list-el span:first-child {
  margin-right: 5px;
}
.header-left__list-el span:last-child {
  font-size: 0.8rem;
}

.header-right {
  display: flex;
  list-style: none;
}

.header-right__list {
  margin-left: 50px;
}
.header-right__list:not(:last-child) {
  border-right: 2px solid #c0bfc1;
  padding-right: 10px;
}

.header-right__list-link {
  font-size: 0.8rem;
}

@media (min-width: 1000px) {
  .header {
    display: flex;
  }
}
@media (min-width: 1200px) {
  .header {
    width: 85%;
  }
}

/* mobile header */

.mobile-header__container {
  width: 97%;
  margin: auto;
}

.mobile-header {
  display: flex;
  justify-content: space-between;
  list-style: none;
  padding: 0;
  align-items: center;
}
.mobile-header__list {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.mobile-header__list span {
  font-size: 0.7rem;
}

.mobile-header__list-link {
  color: #602e6e;
}

@media (min-width: 1000px) {
  .mobile-header {
    display: none;
  }
}
