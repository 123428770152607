footer {
  background-color: #2f3030;
  margin: 20px 0px 0px 0px;
}

.footer-nav {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  width: 85%;
  margin: auto;
  padding: 40px 0px;
  gap: 10px;
}

.footer-nav__container p {
  opacity: 0.7;
  color: #fff;
  font-size: 0.9rem;
  margin-top: 5px;
}

.footer-nav__container-title {
  color: #fff;
  font-size: 0.9rem;
}

.bottom-logo::first-letter {
  /* font-style: oblique; */
  font-style: italic;
  color: #fe0000;
  font-size: 2rem;
  font-family: 'Protest Riot', sans-serif;
}

.footer-nav__container-items {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0px;
}

.footer-nav__container-item__link {
  color: #fff;
  opacity: 0.6;
  display: block;
  margin-bottom: 8px !important;
  font-size: 0.8rem;
  transition: opacity 1s ease;
}

.footer-nav__container-item__link:hover {
  opacity: 1;
}

.footer-nav__container-link__img {
  height: 100px;
  width: 100%;
  object-fit: contain;
  margin-left: -50px;
}

.footer-medias {
  display: flex;
  width: 100%;
  padding: 0;
  transition: background-color 1s ease;
}

.footer-media a {
  display: flex;
  height: 40px;
  width: 40px;
  border: 1px solid #fff;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-right: 10px;
}
.footer-media a:hover .footer-media svg {
  fill: #ffffff;
}
.footer-media a:hover {
  background-color: #8b5bc2;
}

.footer-media svg {
  fill: #c1c1c1;
}

/* @media (min-width: 1200px) {
  .footer-nav {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (min-width: 1200px) {
  .footer-nav {
    grid-template-columns: repeat(4, 1fr);
  }
} */
