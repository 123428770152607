.faq-container {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  width: 97%;
  margin: auto;
  gap: 30px;
}

.faq-container__left img {
  height: 400px;
  width: 100%;
  object-fit: cover;
}

.faq-container__left-description {
  padding: 20px 0px;
}

.faq-container__left-description h2 {
  margin-bottom: 10px;
}

.faq-container__left-description button {
  background-color: #8f62c5;
  margin-top: 10px;
  padding: 8px 12px;
  cursor: pointer;
  color: #fff;
  border: none;
  border-radius: 5px;
}

.faq-container__right-header h2 {
  font-size: 2rem;
  margin-bottom: 10px;
}

.faq-container__right-header p {
  margin-bottom: 10px;
}

.faq-item {
  margin-bottom: 20px;
}

.faq-question {
  display: flex;
  cursor: pointer;
}

.faq-answer {
  margin-top: 5px;
}

@media (min-width: 768px) {
  .faq-container {
    grid-template-columns: repeat(2, 1fr);
    margin: 60px auto;
  }
  .contact-left img {
    height: 700px;
    width: 100%;
    object-fit: cover;
  }

  .faq-container__right {
    padding: 40px;
  }
}

@media (min-width: 1000px) {
  .faq-container {
    width: 90%;
  }
}

@media (min-width: 1200px) {
  .faq-container {
    width: 80%;
  }
}
