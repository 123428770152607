.empty-cart-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 300px;
}
.empty-cart {
  text-align: center;
}
h2 {
  font-size: 1.2rem;
  margin-bottom: 10px;
}
p {
  font-size: 16px;
  color: #666;
  margin-bottom: 20px;
}
.btn-back-to-shop {
  display: inline-block;
  padding: 10px 20px;
  background-color: #4b02a4;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}
.btn-back-to-shop:hover {
  background-color: #3e0682;
}
@media (min-width: 768px) {
  h2 {
    font-size: 2rem;
    margin-bottom: 10px;
  }
}
