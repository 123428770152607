.shop-main {
  padding-bottom: 30px;
}
.shop-main__header {
  display: none;
}
.shop-main__content {
  display: grid;
  grid-template-columns: auto;
}

/* HeaderCategories componant */

.shop-main {
  width: 96%;
  margin: auto;
}

.header-categorie {
  padding: 10px 0px;
}

.header-categorie_bars-items {
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0px;
  padding: 0px;
}

.header-categorie__titles {
  display: flex;
  padding: 20px 0px 0px 20px;
}

.header-categorie__title {
  padding: 0px;
  margin: 0px;
  font-size: 1.5rem;
  color: #011b43;
  font-weight: 900;
}

.header-categorie__number {
  margin-left: 10px;
  display: block;
  opacity: 0.7;
}

.header-categorie__catalogs {
  display: flex;
  list-style: none;
  padding: 0px;
  margin: 0px;
  width: 100%;
}

.header-categorie__catalog {
  list-style: none;
  margin-right: 20px;
}

.header-categorie__catalog-button {
  padding: 5px 10px;
  border: 1.5px solid rgba(141, 141, 141, 0.322);
  cursor: pointer;
  font-weight: 600;
  border-radius: 5px;
  font-size: 0.7rem;
}
@media (min-width: 1200px) {
  .shop-main {
    width: 90%;
  }
  .header-categorie__catalog-button {
    font-size: 0.8rem;
  }
}

/* MobileHeader componant */
.mobile__header-select {
  display: flex;
  padding: 10px 0px;
  justify-content: space-between;
}
.mobile__header-select__el {
  width: 48%;
}
.mobile__header-select__el h5 {
  padding: 0;
  margin: 0;
}
.mobile__header-select__el .text-input {
  width: 100%;
}

@media (min-width: 1000px) {
  .mobile__header-select {
    display: none;
  }
}
/* HeaderFilter composant */
.header-filter {
  margin-top: 10px;
}

.header-filter__title {
  padding: 0px;
  margin: 0px;
}

.header-filter__container {
  display: flex;
  justify-content: space-between;
}

.header-filter__container-items {
  list-style: none;
  display: flex;
  padding: 0px;
  margin: 0px;
  align-items: center;
}

.header-filter__container-btns__lists {
  list-style: none;
}

.header-filter__container-item__input {
  padding: 6px 5px;
  outline: none;
  border: 2px solid #9650b466;
  border-radius: 8px;
  margin-right: 65px;
}

.header-filter__container-item__select {
  padding: 6px 5px;
  outline: none;
  border: 2px solid #9650b466;
  border-radius: 8px;
  color: #580c79f4;
}

.header-filter__container-btns__lists {
  display: flex;
  align-items: center;
}
.header-filter__container-btn {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

/*  */
.content-right__card {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-auto-rows: 400px;
  gap: 20px;
}

/* ContentLeft */
.content-left {
  display: none;
}
.content-left__els {
  margin: 0px;
  padding: 0px;
  list-style: none;
  margin-top: 15px;
}

.content-left__el {
  margin-bottom: 8px;
}
.content-left__el label {
  font-weight: 500;
}
.content-left__el input {
  padding: 100px;
  transform: scale(1.3);
  border-radius: 5px;
  margin-right: 10px;
}

.content-left__title {
  padding: 0px 0px 5px 0px;
  margin: 0px;
}

.content-left__els-price {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  /* flex-direction: column; */
}

.content-left__els-price__from {
  display: flex;
  flex-direction: column;
  width: 36%;
  outline: none;
}

.content-left__els-price__from input {
  outline: none;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border: 2px solid #d5b9e1;
  padding: 4px 2px;
}

.content-left__els-price__from span {
  font-size: 1rem;
  opacity: 0.6;
}

.content-left__els-price__before {
  display: flex;
  flex-direction: column;
  width: 36%;
}
.content-left__els-price__before span {
  font-size: 1rem;
  opacity: 0.6;
}
.content-left__els-price__before input {
  outline: none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border: 2px solid #d5b9e1;
  border-left: none;
  padding: 4px 2px;
}

@media (min-width: 1000px) {
  .shop-main__header {
    display: block;
  }
  .shop-main__content {
    grid-template-columns: 20% auto;
  }
  .content-left {
    display: block;
  }
}

@media (min-width: 1200px) {
  .content-right__card > * {
    max-width: 350px;
  }
}
